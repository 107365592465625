/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone, OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import {ButtonInterface, GlobalPopup, GlobalPopupType} from '../../global-popup';
import {TooltipService} from '../../../../shared/services/tooltip/tooltip.service';
import {GlobalAlertService} from '../../global-alert.service';
import {GlobalModel} from '../../../../shared/services/state/global.model';
import Utils from '../../../../shared/utils/utils';
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import Timer = NodeJS.Timer;
import {PopupDisplayMode} from './default-popup-wrapper.interface';
import {LoggerService} from "../../../../shared/services/logger/logger.service";

@Component({
    selector: 'default-popup-wrapper-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div (globalKeydownOutsideAngular)="handleGlobalKeyDown($event)"
             (globalKeyupOutsideAngular)="handleGlobalKeyUp($event)" style="animation-duration: 0.2s"
             class="{{getDisplayModeClass()}} alert px-sm-4 px-3 animated fadeIn z-depth-3 global-alert 
                    {{globalPopup.removed?'global-alert-removed':''}}
                    {{globalPopup.type === GlobalPopupType.FIRST_LOGIN ? 'first-login-popup '+' state-'+globalPopup.label:''}}"
             role="alert">
            <i class="material-icons global-alert-close-icon {{globalPopup.removed || showLoader || hideCloseButton?'d-none':''}}"
               role="button" (click)="closePopup()">close</i>
            <div class="form-group-label-container {{(!title && !infoText) || showLoader?'d-none':''}} mt-2">
                <div *ngIf="globalPopup.subtitle || globalPopup.img" class="first-login-icon yellow-shadow"><i class="material-icons md-light md-24 widget-icon">auto_awesome</i></div>
                <div class="this-is-a-firefox-fix" style="display: grid; display: -ms-grid;">
                    <h1 class="{{title?'':'d-none'}} text-truncate {{description?'mb-0':'mb-2'}} pb-0">{{title}}</h1>
                </div>
                <help-icon-component style="margin-bottom:auto; margin-top:0.45rem;" class="{{infoText?'':'d-none'}}"
                                     [tooltipText]="infoText"
                                     [tooltipPlacement]="PLACEMENT_BOTTOM"></help-icon-component>
            </div>
            <div *ngIf="globalPopup.subtitle" class="mb-4 mt-0"><br><h5>{{globalPopup.subtitle}}</h5></div>
            <div *ngIf="!idleTimer" class="{{description && !showLoader?'mb-2 mt-1':'d-none'}}"
                 [innerHTML]=description></div>
            <div *ngIf="idleTimer"> {{this.ts.translate('idletimer.popuptext1')}} {{idleTimeleft}} {{this.ts.translate('idletimer.popuptext2')}}</div>
            <div *ngIf="showLoader" class="d-flex justify-content-center">
                <!--<div class="pr-3" style="line-height: 32px;height: 32px;">{{'Gegevens ophalen...' | translate}}</div>-->
                <div class="loader" style="top:0;"></div>
            </div>

            <!-- NOTE: bewaren - alternatieve loader -->
            <!--<div style="margin-right:0.75rem;" class="form-attachment-loader pt-2 {{showLoader?'':'d-none'}}">
                <div class="progress">
                    <div class="indeterminate"></div>
                </div>
            </div>-->

            <ng-content *ngIf="!showLoader"></ng-content>

            <div *ngIf="globalPopup.img && globalPopup.img !== 'null'" class="manual-image full-width"><img class="img-fluid" src="{{globalPopup.img}}" alt="image"></div>
            
            <div *ngIf="componentRef && !showLoader && !hideButtons && globalPopup.buttonArray" class="pt-2 d-flex justify-content-end popup-button-container">
                <ng-container *ngFor="let button of globalPopup.buttonArray; let i = index">
                    <lumi-button (onClick)="componentRef.handlePopupAction($event, globalPopup, button)"
                                 [ngClass]="'popup-btn-'+button.code"
                                 [disabled]="isButtonDisabled(button)"
                                 [size]="'large'"
                                 [label]="button.label"
                                 [rank]="button.isPrimary? 'secondary':'tertiary'">
                    </lumi-button>
                </ng-container>
            </div>
        </div>
    `,
    styleUrls:['default-popup-wrapper.component.scss']
})
export class DefaultPopupWrapperComponent implements OnInit{
    @Input() componentRef: AbstractBasePopupComponent = null;
    @Input() globalPopup: GlobalPopup = null;
    @Input() title: string = '';
    @Input() description: string = '';
    @Input() hideButtons: boolean = false;
    @Input() hideCloseButton: boolean = false;
    @Input() showLoader: boolean = false;
    @Input() infoText: string = '';
    @Input() displayMode: string = 'default';
    @Input() disableButtons: boolean = false;
    @Input() disableButtonsOverride: (ButtonInterface) => boolean = null;

    @Output('onClose') onClose: EventEmitter<MouseEvent> = new EventEmitter();

    idleTimeleft: number = 0;
    private timerInterval: any;
    public idleTimer: boolean;
    private disableKeyUpListener = true;

    constructor(
        private cd: ChangeDetectorRef,
        private ngZone: NgZone,
        private globalModel: GlobalModel,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        setTimeout(() => {
            this.disableKeyUpListener = false;
        }, 500);
    }

    // Change detection from the ng-content component doesn't trigger an update in this wrapper. If you want to update buttons for example, you'll need to run this function manually
    public runChangeDetection(): void {
        this.cd.detectChanges();
    }

    isButtonDisabled(button: ButtonInterface): boolean {
        // When a personal check function is available, use that one
        // NOTE: disableButtonsOverride completely overwrites disabled-functionality. So readonly configs too.
        if (this.disableButtonsOverride) {
            return this.disableButtonsOverride(button);
        }

        // In other cases, check the disableButtons var
        return this.disableButtons;
    }

    ngOnInit(): void {
        // Blur the currently focused item, so popup trigger, like the form buttons, don't trigger again when pressing enter
        if (document.activeElement) {
            setTimeout(Utils.removeAllFocus);
        }

        // Check for a timer
        if (this.globalPopup && this.globalPopup.data && this.globalPopup.data.idleTimer != undefined) {
            if(this.globalPopup.data.idleTimer){
                this.idleTimer = true;
                this.idleTimeleft = this.globalPopup.data.idlePopupTime;
                this.timerInterval = setInterval(() => {
                    if (this.idleTimeleft > 0) {
                        this.idleTimeleft--;
                        this.cd.detectChanges();
                    }
                    else {
                        clearInterval(this.timerInterval);
                    }
                }, 1000);
            }
        } else {
            this.idleTimer = false;
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.timerInterval);
    }
    
    handleGlobalKeyUp(event: any): void {
        if (this.disableKeyUpListener) {
            return;
        }
        // NOTE: this event might trigger twice when a popup contains multiple elements who all listen to the GLOBAL key up (like a map and textarea). So make it safe

        // Delay this event so it will always be last to execute (multiple of the same events are registered on the window). This will allow other components to hijack the event, before it is handled here
        setTimeout(() => {
            // If another component tells the wrapper to ignore it, skip the key handling
            // Skip textarea's in popups, they need to do the enter in the text, not submit the form
            if (event.ignore) {
                this.logger.log('[GlobalAlertComponent] ' + 'event.ignore:' + (<{ ignore?: boolean }>event).ignore);
                return;
            }
    
            if ((<HTMLInputElement>event.target).nodeName === 'TEXTAREA') {
                this.logger.log('[GlobalAlertComponent] ' + 'ignore; target was textarea:' + event.target);
                return;
            }
    
            if (!Utils.isBody(<Element>event.target) && Utils.hasFocus(<Element>event.target)) {
                this.logger.log('[GlobalAlertComponent] ' + 'ignore; event target has focus:' + event.target);
                return;
            }

            if (this.isTopLevelPopup()) {
                if (Utils.isEscapeKey(event)) {
                    this.closePopup();
                    this.logger.log('[GlobalAlertComponent] ' + 'escape pressed, try to click close');
                }

                // Handle enter key here, in key up, to work with textarea's
                if (event.key === 'Enter') {

                    this.logger.log('[DefaultPopupWrapperComponent] ' + 'enter key pressed');
                    if (!this.globalPopup.buttonArray.some(button => button.isPrimary)) {
                        this.logger.log('[GlobalAlertComponent] ' + 'no primary buttons found, no click executed');
                    }
                    this.globalPopup.buttonArray.forEach((button) => {
                        if (button.isPrimary) {
                            this.logger.log('[DefaultPopupWrapperComponent] ', button.label);
                            // Check for disabled buttons, they should be ignored
                            if (!this.isButtonDisabled(button)) {
                                this.componentRef.handlePopupAction(null, this.globalPopup, button);
                            }
                        }
                    });
                }
            }
        });
    }

    handleGlobalKeyDown(event: KeyboardEvent): void {
        // Ignore key presses on input fields. The keys need to be handled inside those components, not this wrapper
        if (!((<HTMLInputElement>event.target).nodeName == 'TEXTAREA' || (<HTMLInputElement>event.target).nodeName == 'INPUT')) {
            if (this.isTopLevelPopup()) {
                this.ngZone.run(() => {
                    if (event.keyCode == 37) {
                        this.makePrimary(true);
                        this.cd.detectChanges();
                    }

                    if (event.keyCode == 39) {
                        this.makePrimary(false);
                        this.cd.detectChanges();
                    }
                });
            }
        }
    }

    private makePrimary(left: boolean): void {
        // Null pointer protection
        if (!this.globalPopup.buttonArray || this.globalPopup.buttonArray.length <= 0) {
            return;
        }

        let primaryIndex: number = 0;
        let newIndex: number = null;

        for (let i = 0; i < this.globalPopup.buttonArray.length; i++) {
            if (this.globalPopup.buttonArray[i].isPrimary) {
                primaryIndex = i;
                this.globalPopup.buttonArray[i].isPrimary = false;

                if (left) {
                    if (primaryIndex <= 0) {
                        primaryIndex = this.globalPopup.buttonArray.length;
                    }
                    newIndex = primaryIndex - 1;
                }
                else {
                    if (primaryIndex + 1 >= this.globalPopup.buttonArray.length) {
                        primaryIndex = -1;
                    }
                    newIndex = primaryIndex + 1;
                }
                this.globalPopup.buttonArray[newIndex].isPrimary = true;

                break;
            }
        }
    }

    private isTopLevelPopup(): boolean {
        return this.globalModel.globalPopups.value[this.globalModel.globalPopups.value.length - 1].type == this.globalPopup.type;
    }

    closePopup(): void {
        if (this.hideCloseButton) {
            return;
        }

        this.onClose.emit(null);
    }

    getDisplayModeClass(): string {
        if (this.displayMode == PopupDisplayMode.SMALL) {
            return 'small-mode';
        }
        if (this.displayMode == PopupDisplayMode.LARGE) {
            return 'large-mode';
        }
        return '';
    }

    get PLACEMENT_BOTTOM(): string {
        return TooltipService.PLACEMENT_BOTTOM;
    }

    protected readonly GlobalPopupType = GlobalPopupType;
}
