import {NgModule} from '@angular/core';
import {GuiFormComponent} from './gui-form.component';
import {CommonModule} from '@angular/common';
import {GatewayResetComponent} from './components/gateway-reset/gateway-reset.component';
import {CreateStedinOrderComponent} from './components/create-stedin-order/create-stedin-order.component';
import {CommonUIModule} from '../commonUI/common-ui.module';
import {DeviceStateComponent} from './components/device-state/device-state.component';
import {InteractiveTableComponent} from './components/interactive-table/interactive-table.component';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {LumiFormModule} from '../form/lumi-form.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CreateDeviceMappingComponent} from './components/create-device-mapping/create-device-mapping.component';
import {FormTabsComponent} from "./components/form-tabs/form-tabs.component";
import {FormStepsComponent} from "./components/form-steps/form-steps.component";
import {FormInfoComponent} from "./components/form-info/form-info.component";

@NgModule({
    imports: [
        CommonModule,
        CommonUIModule,
        CommonPipesModule,
        LumiFormModule,
        MatTooltipModule
    ],
    providers: [],
    declarations: [
        GuiFormComponent,
        GatewayResetComponent,
        DeviceStateComponent,
        InteractiveTableComponent,
        CreateStedinOrderComponent,
        CreateDeviceMappingComponent,
        FormTabsComponent,
        FormStepsComponent,
        FormInfoComponent
    ],
    exports: [
        GuiFormComponent,
        GatewayResetComponent,
        CreateStedinOrderComponent
    ]
})
export class GuiFormModule {
    constructor() {
    }
}
