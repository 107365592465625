export enum GuiInterface {
    GATEWAY_RESET = 'gateway-reset',
    DEVICE_STATE = 'device-state',
    CREATE_STEDIN_ORDER = 'create-stedin-order',
    INTERACTIVE_TABLE = 'interactive-table',
    DEVICE_CREATE_MAPPING = 'device-create-mapping',
    TABS = 'tabs',
    MENU = 'menu',
    INFO = 'info'
}

export interface GuiChildConfig {
    type: string
    url_prefix: string
}

export interface GuiChildAltConfig {
    attr: {
        type: string
        url_prefix: string
        disabled?: boolean
    }
    label: string
}

export interface StedinChildConfig extends GuiChildConfig {
    externalId: string;
    hasKabelBon: boolean;
    werkorderId: number;
    kabelBonBaseObjectId: number;
    werkorderBaseObjectId: number;
    latitude: number;
    longitude: number;
}
