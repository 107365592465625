import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {GlobalPopup} from "../global-popup";
import {HTTPError} from "../../../shared/services/http/http-error";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {FormComponent} from "../../../shared/components/form/containers/form/form.component";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";
import {ITabs} from "../../../shared/components/gui-form/components/form-tabs/form-tabs.interface";

@Component ({
    selector: 'global-popup-create-user-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtons]="readOnly" [globalPopup]="globalPopup" [showLoader]="isLoading" (onClose)="closePopup($event)">
            <div class="pr-0">
                <div *ngIf="formData && config">
                    <form-component #form
                        [config]="config"
                        [label]="formData.schema.label"
                        [name]="getFormName()"
                        [validationConstraints]="validationConstraints"
                        [invalidControlsErrors]="invalidControlsErrors"
                        [readOnly]="readOnly"
                        [formIsSubmitted]="formIsSubmitted"
                        (onComponentEvent)="handleComponentEvent($event)">
                    </form-component>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupCreateUserComponent extends AbstractBasePopupComponent implements AfterViewInit {
    @Input() requestUrl:string = "";
    @Input() submitUrl:string = "";

    public currentStep:number = 1;

    @ViewChild('form', {static: false}) form:any;

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected elementRef:ElementRef, private model:GlobalModel, protected ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit(){
        //Retrieve map item id from the popup data
        this.requestUrl = this.globalPopup.data.requestUrl;
        this.submitUrl = this.globalPopup.data.submitUrl;

        this.submitButton = this.getSubmitButton();

        this.requestFormData();
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //callback for submit button comes from this form, not from outside this component
        if (button == this.submitButton) {
            //Next / finish button
            this.form.handleComponentEvent({event: FormEvent.SAVE, data: {}});
        } else if (button.code == "PREV_BTN"){
            //Previous button
            this.form.handleComponentEvent({event: FormEvent.PREVIOUS_BTN, data: {}});
        } else {
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }

    public handleComponentEvent(eventData:any):void
    {
        switch (eventData.event) {
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, false, this.currentStep == this.guiTabs().length);
                break;
            case FormEvent.PREVIOUS_BTN:
                this.submitFormData(eventData.data.formData, true);
                break;
        }
    }

    private submitFormData(form: any, previousStep:boolean = false, lastStep:boolean = false):void
    {
        this.isLoading = true;

        let urlToSubmit:string = '';
        if(previousStep){
            urlToSubmit = this.submitUrl+'?previous=true'
        } else if (lastStep) {
            urlToSubmit = this.submitUrl+'?last=true'
        } else {
            urlToSubmit = this.submitUrl
        }



        this.formDataService.setFormData(form, this.getFormName(), urlToSubmit,
            (formData:any) => {
                if(!lastStep){
                    this.handleSubmitResponse();
                    this.handleResponse(formData);
                } else {
                    let returnData:any = formData;
                    if(this.globalPopup.data.isWizard){
                        returnData = {'baseObjectId':0}
                    }
                    this.onPopupAction.emit({event:null, alert:this.globalPopup, button:this.submitButton, data:returnData});
                }

            },
            (failure: RequestFailure) => {
                this.logger.log("[GlobalPopupCreateGenericReportComponent] " + "Handle failure response: ", failure);
                this.handleSubmitResponse(failure.formErrors);
                this.handleResponse(null);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private handleResponse(formData){
        if(formData){
            this.formData = formData;
        }
        this.setCurrentWizardStep();
        this.isLoading = false;
        this.cd.detectChanges();
        this.manipulateButtons();
    }

    private manipulateButtons():void{
        const backBtn = $('.popup-btn-PREV_BTN');
        const submitBtn = $('.popup-btn-OK');
        if(this.currentStep == 1){
            backBtn.hide();
        } else {
            backBtn.show();
        }
        if(this.currentStep == this.guiTabs().length){
            submitBtn.children('button').children('span').text(this.ts.translate('Aanmaken'))
        }
    }

    public guiTabs():ITabs[]{
        let guiTabs:ITabs[] = [{
            baseObjectId:null,
            previousBaseObjectId:null,
            label:null,
            tabIndex:null,
            active:true
        }]
        if(this.globalPopup.data.isWizard){
            guiTabs = this.formData?.schema?.children
                .find((_x)=>{return _x.name =="menu"})?.children
                .find((_x)=>{return _x.type =="gui"})?.attr?.menu;
        }
        return guiTabs;
    }

    private setCurrentWizardStep():void{
        this.guiTabs().map((_x, index) => {
            if(_x.active){
                this.currentStep = index+1;
            }
        })
    }

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, true);
    }

    public requestFormData():void
    {
        this.formDataService.getFormData(this.requestUrl,
            (formData:any) => {
                this.formData = formData;
                this.isLoading = false;
                this.cd.detectChanges();
                this.manipulateButtons();
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }
}
