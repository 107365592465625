<div class="sp-subStatus-container" *ngIf="subStatuses?.length > 0">
    <ng-container *ngFor="let subStatus of subStatuses">
        <div class="sp-subStatus">
            <div class="sp-subStatus-icon-holder" *ngIf="subStatus.icon" [ngStyle]="getBorderColor(subStatus)">
                <div class="sp-subStatus-icon">
                    <i [ngStyle]="getColor(subStatus)">{{subStatus.icon}}</i>
                </div>
            </div>
            <div class="sp-subStatus-description" [title]="subStatus.description">
                <strong class="font-weight-heavy" style="font-size: 0.8rem" *ngIf="subStatus.title">{{subStatus.title}}</strong>
                <div [innerHTML]="subStatus.description"></div>
            </div>
        </div>
    </ng-container>
</div>
