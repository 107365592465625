<ng-container>
    <div [ngSwitch]="config.attr.type">
        <gateway-reset *ngSwitchCase="guiType.GATEWAY_RESET" [config]="config.attr"></gateway-reset>
        <device-state *ngSwitchCase="guiType.DEVICE_STATE" [config]="config.attr"></device-state>
        <create-stedin-order *ngSwitchCase="guiType.CREATE_STEDIN_ORDER" [config]="config.attr"></create-stedin-order>
        <interactive-table *ngSwitchCase="guiType.INTERACTIVE_TABLE" [config]="config"></interactive-table>
        <create-device-mapping *ngSwitchCase="guiType.DEVICE_CREATE_MAPPING" [config]="config"></create-device-mapping>
        <form-tabs *ngSwitchCase="guiType.TABS" [config]="config"></form-tabs>
        <form-steps *ngSwitchCase="guiType.MENU" [config]="config"></form-steps>
        <form-info *ngSwitchCase="guiType.INFO" [config]="config"></form-info>
    </div>
</ng-container>
