import {ProjectsManageButtonAction} from '../../../../../modules/projectsManage/form/projects-manage-form.interface';
import {SubStatusItem} from '../../../status/sub-statuses/sub-statuses.interface';
import {DateTextInitialValue} from '../form-input/form-input.interface';

export enum ConfigType {
    'object' = 'object',
    'group_with_status' = 'group_with_status',
    'string' = 'string',
    'text_area' = 'text_area',
    'integer' = 'integer',
    'range' = 'range',
    'email' = 'email',
    'date_time_text' = 'date_time_text',
    'date_text' = 'date_text',
    'time' = 'time',
    'select' = 'select',
    'multi_select' = 'multi_select',
    'checkbox' = 'checkbox',
    'color_picker' = 'color_picker',
    'color_picker_advanced' = 'color_picker_advanced',
    'button' = 'button',
    'file' = 'file',
    'password' = 'password',
    'graph' = 'graph',
    'table' = 'table',
    'auth_table' = 'auth_table',
    'auth_action_table' = 'auth_action_table',
    'formfield_settings_table' = 'formfield_settings_table',
    'anchor' = 'anchor',
    'image' = 'image',
    'map_item_tree' = 'map_item_tree',
    'location' = 'location',
    'item_count' = 'item_count',
    'dimming_exceptions' = 'dimming_exceptions',
    'dimming_scheme' = 'dimming_scheme',
    'inline_button' = 'inline_button',
    'log_component' = 'log_component',
    'comment_component' = 'comment_component',
    'map_component' = 'map_component',
    'attachment_component' = 'attachment_component',
    'attachment_simple_component' = 'attachment_simple_component',
    'attachment_single_component' = 'attachment_single_component',
    'work_preparation_component' = 'work_preparation_component',
    'work_activity_component' = 'work_activity_component',
    'check_activity_component' = 'check_activity_component',
    'work_specification_component' = 'work_specification_component',
    'weekprijs_component' = 'weekprijs_component',
    'form_link' = 'form_link',
    'time_registration' = 'time_registration',
    'status' = 'status',
    'file_manager' = 'file_manager',
    'map_icon_picker' = 'map_icon_picker',
    'form_map_server' = 'form_map_server',
    'timeline' = 'timeline',
    'gui' = 'gui'
}

export interface BaseFormConfig {
    type: ConfigType;
    attr: {[key: string]: any}
    name: string;
    disabled: boolean;
    required?: boolean;
    action?: string;
    label?: string;
    children?: BaseFormConfig[]
}

export interface InputConfig extends BaseFormConfig {
    label?: string;
    initialValue?: any;
}

export interface FormGroupConfig extends BaseFormConfig {
    children: BaseFormConfig[];
    base_object_id?: number;
    referenceId?: number;
    attr: {
        selectAllCheckboxGroups?: boolean;
        subStatuses?: SubStatusItem[];
        visible?: boolean;
        hiddenField?: boolean;
        description?: string;
        collapsible: boolean;
        headerButton: ButtonConfig;
    }
}

export interface ButtonBarConfig extends BaseFormConfig {
    attr: {
        class: 'button-bar-fixed';
        hostClasses: 'button-bar-fixed-container';
        isFixedTopButtonBar: true;
    };
    children: ButtonConfig[];
}

export interface ButtonConfig extends BaseFormConfig {
    attr: {
        toolTip: string;
        icon: string;
        action: string|DefaultButtonAction|ProjectsManageButtonAction;
        url?: string;
        map_item_id?: number;
        deleteUrl?: string;
        baseObjectId?: number;
        dropDownData?: {
            id: number;
            label: string;
        }
    };
    label: string,
    type: ConfigType.button;
}

export interface FormRowConfig extends FormGroupConfig {
    type: ConfigType.object;
    attr: {
        selectAllCheckboxGroups?: boolean;
        description?: string;
        class: 'form-row';
        collapsible: boolean;
        crushTranslator: boolean;
        section: {
            icon: string;
            count?: number;
        };
        headerButton: ButtonConfig;
        subStatuses?: SubStatusItem[];
        archived: boolean
    }
    base_object_id: number;
    initialValue: {
        [key: string]: string | boolean[] | {}
    };
}

export enum DefaultButtonAction {
    'cancel' = 'cancel',
    'save' = 'save',
}

export enum ConfigName {
    BUTTON_GROUP = 'buttonGroup',
    DROPDOWN = 'dropdown'
}
