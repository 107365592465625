/**
 * Created by Christiaan on 13/03/2017.
 */
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {HTTPService} from "../../../shared/services/http/http.service";
import {AppSettings} from "../../../app.settings";
import {UserService} from "../user.service";
import {Subscription} from "rxjs";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {LuminizerRoutes} from '../../../shared/interfaces/routes';
import {PasswordGeneratorService} from '../../../shared/services/authorization/password-generator.service';
import {User} from "../user";
import {LoginService} from "../login.service";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'password-new-component',
    providers: [PasswordGeneratorService],
    templateUrl: 'password-new.component.html',
    styleUrls:['../../../shared/components/form/components/form-input/form-password-input.component.scss']
})
export class PasswordNewComponent implements OnInit, OnDestroy {

    @ViewChild('newPassword', {static: false}) newPasswordInput:any;
    @ViewChild('repeatPassword', {static: false}) repeatPasswordInput:any;

    public strengthLevel: number = 0;
    public strengthText:string = '&nbsp;';
    
    public passwordForm:UntypedFormGroup;
    public failure:RequestFailure;
    private token:string;
    private subChangeLanguage:Subscription;
    public flippingLanguage:boolean = false;
    private checkingLoginStatus:boolean = false;
    private firstTimeVisible:boolean = true;
    public _user:User = null;
    
    public formTitle:string = "";
    public formSubTitle:string = "";
    public formNewPassword:string = "";
    public formRepeatPassword:string = "";
    private formSubmit:string = "";

    public disableSubmitButton:boolean = false;
    
    constructor(public httpService:HTTPService, private loginService:LoginService, private formBuilder:UntypedFormBuilder, private model:GlobalModel, private router:Router, private userService:UserService, private cd:ChangeDetectorRef, private ts:TranslateService, public generatorService:PasswordGeneratorService, protected logger:LoggerService, private translate:TranslateService)
    {
        this.createForm();
        this.token = this.userService.getTokenParam();

        this.logger.log("[PasswordNewComponent] " + "Token: " + this.token);
    }

    ngOnInit()
    {
        this.subChangeLanguage = this.model.changeLanguage.subscribe( () =>
        {
            this.formTitle = this.ts.translate('Nieuw wachtwoord');
            this.formSubTitle = this.ts.translate('Geef een nieuw wachtwoord op. Gebruik minimaal 8 tekens, met minimaal 1 hoofdletter en 1 cijfer of speciaal teken.');
            this.formNewPassword = this.ts.translate('Nieuw wachtwoord');
            this.formRepeatPassword = this.ts.translate('Herhaal wachtwoord');
            this.formSubmit = this.ts.translate('Verstuur');

            this.cd.detectChanges();
        })
    }

    public checkPasswordStrength(passwordInput:any) {
        const password = passwordInput.target.value;
        let strength = 0;
        if(password.length > 0){
            strength = 1;
        }
        if(password.length >= 8){
            if (/[a-z]/.test(password))strength++; // Check lowercase
            if (/[A-Z]/.test(password))strength++; // Check uppercase
            if (/[0-9]/.test(password))strength++; // Check digits
            if (/[^a-zA-Z0-9]/.test(password))strength++; // Check special characters
        }

        if(strength == 1){
            this.strengthText = this.translate.translate('password-strength.weak');
        } else if(strength == 2 || strength == 3){
            this.strengthText = this.translate.translate('password-strength.medium');
        } else if(strength >= 4){
            this.strengthText = this.translate.translate('password-strength.strong');
        } else {
            this.strengthText = '&nbsp;';
        }

        this.strengthLevel = strength;
    }

    getLogoPath(): string {
        if(this.model.isXmasMode()){
            return 'assets/img/luminizerlogo-xmas.png';
        } else {
            return '/assets/img/luminizerlogo.png';
        }
    }

    public isLoginVisible(user:User):boolean{

        if (!this.checkingLoginStatus && !this.loginService.isLoggedInLocally(user) && !this.flippingLanguage){

            //TODO: iets mooiers maken hiervoor. Door de animatie die in het loginscherm zit is het element niet beschikbaar
            //Daarvoor wordt die timer ingezet
            if (this.firstTimeVisible){
                this.firstTimeVisible = false;

                setTimeout(() => {
                    if (this.newPasswordInput) {
                        this.newPasswordInput.nativeElement.focus();
                    }
                }, 500);
            }

            return true;
        }else{
            return false;
        }
    }

    public handleFlippingLanguage(flippingLanguage:boolean){
        this.flippingLanguage = flippingLanguage;
    }

    ngOnDestroy(): void
    {
        if (this.subChangeLanguage){
            this.subChangeLanguage.unsubscribe();
        }
    }

    private createForm():void
    {
        this.passwordForm = this.formBuilder.group({
            newPassword: ['', Validators.required],
            repeatPassword: ['', Validators.compose([Validators.required])]
        })
    }
    
    public handleShowGenerator():void {
        this.generatorService.handleShowGenerator();
        this.cd.detectChanges();
    }
    
    public handleChangePasswordLength(e:any):void {
        this.generatorService.handleChangePasswordLength(e);
        this.cd.detectChanges();
    }
    
    public handleClickNumbers():void {
        this.generatorService.handleClickNumbers();
        this.cd.detectChanges();
    }
    
    public handleClickSymbols():void {
        this.generatorService.handleClickSymbols();
        this.cd.detectChanges();
    }

    //TODO: opruimen en implementatie afmaken
    public handleClickSend(event:MouseEvent):void
    {
        this.failure = null;
        this.disableSubmitButton = true;
        this.cd.detectChanges();

        this.logger.log("[PasswordNewComponent] " + "Execute call");
        //When a call is performed, the app is blocked
        this.userService.changePassword(
            this.token,
            this.passwordForm.value.newPassword,
            this.passwordForm.value.repeatPassword,
            () => {
                this.logger.log("[PasswordNewComponent] " + "Password changed successfully, redirect to login-page, with info popup");
                this.router.navigate([LuminizerRoutes.LOGIN_PAGE], { queryParams: {isNewPassword:"true"}});
            },
            (failure:RequestFailure) => {
                //Changing the password did not succeed
                this.logger.log("[PasswordNewComponent] " + "Changing password failed" + failure);
                this.handleFailure(failure);
            }
        )
    }

    private handleFailure(failure:RequestFailure):void
    {
        this.newPasswordInput.nativeElement.focus();
        this.failure = failure;
        this.disableSubmitButton = false;
        this.cd.detectChanges();
    }
    
    public handleKeyDown($event: any) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
            this.handleClickSend($event);
        }
    }
}
