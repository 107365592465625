import {
    Component,
    ElementRef,
    Input,
    Output,
    ViewChild,
    EventEmitter,
    AfterViewInit,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';
import {MapItem} from './map-device-mapping-mapitem.component';
import {Subscription} from "rxjs";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {
    IFreeVsa,
    IMapItem,
    IMarker,
    IOccupiedVsa,
    ISelectedMapItem,
    IVsaInfo
} from './map-device-mapping.interface';
import {MapDeviceMappingService} from './map-device-mapping.service';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {finalize, take} from "rxjs/operators";
import {GlobalEvent} from "../../../../shared/interfaces/global-event";
import {GlobalModel} from "../../../../shared/services/state/global.model";
import {HTTPService} from "../../../../shared/services/http/http.service";
import {ButtonCode} from "../../global-popup";
import {GlobalAlertService} from "../../global-alert.service";
import {LumiSelectOption} from "../../../../shared/components/commonUI/select/lumi-select/lumi-select.interface";

@Component({
    selector: 'map-device-mapping',
    templateUrl: 'map-device-mapping.component.html',
    styleUrls: ['map-device-mapping.component.scss']
})
export class MapDeviceMappingComponent implements OnInit, AfterViewInit {
    @Input() popupData: IMarker = null;
    @Output() onChangeLoadingState = new EventEmitter<boolean>();
    @Output() closePopup = new EventEmitter<boolean>();
    @ViewChild('mapContainer', {static: false}) mapContainer: ElementRef;

    private subscriptions: Subscription[] = [];
    public map: google.maps.Map;
    public assetMarker: MapItem = null;
    public bBoxItems: MapItem[] = [];
    private eventDragend:google.maps.MapsEventListener = null;
    private eventZoomChanged:google.maps.MapsEventListener = null;
    private selectedBaseObjectId:number = null;
    public showUnmapBtn:boolean = false;
    public isMapBtnDisabled:boolean = true;

    public infoWindowOpen:boolean = false;
    public infoWindowLoading:boolean = true;
    public selectedMapItem:IMapItem = null;
    public selectedMapItemInfo:ISelectedMapItem = null;
    public availableDriverList:LumiSelectOption[] = [];
    public selectedDriverToMapTo:LumiSelectOption = null;
    public popupIntroText:string = '';
    public popupIntroUnlinkText:string = '';


    constructor(protected httpService: HTTPService, protected logger:LoggerService, private mapDeviceMappingService:MapDeviceMappingService, private model:GlobalModel, public ts: TranslateService, private cd: ChangeDetectorRef, private globalAlertService: GlobalAlertService) {}

    ngOnInit() {
        this.showUnmapBtn = this.popupData.showUnMapButton;
    }

    ngAfterViewInit() {
        this.logger.log('[MapDeviceMapping] ' + 'Initialize map')
        this.map = new google.maps.Map(this.mapContainer.nativeElement, {
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: true,
            mapTypeId: 'roadmap',
            backgroundColor: '#f9f9f9',
            styles: [{featureType:"poi", elementType:"labels", stylers:[{visibility:"off"}]},{featureType:"transit",stylers:[{visibility:"off"}]}],
            zoom:21,
        });

        //Add event for drag
        this.eventDragend = google.maps.event.addListener(this.map, 'dragend', () => {
            this.getBbox()
        })
        //Add event for zoom
        this.eventZoomChanged = google.maps.event.addListener(this.map, 'zoom_changed', () => {
            this.getBbox()
        })
        //Initial bbox. Ensure that map is fully loaded and bounds are set
        google.maps.event.addListenerOnce(this.map, 'idle', () => {
            this.getBbox();
        });

        this.initSourceMarker();
        this.onChangeLoadingState.emit(false)
    }

    private initSourceMarker(){
        this.logger.log('[MapDeviceMapping] ' + 'Initialize source marker')
        this.assetMarker = new MapItem(this)
        this.assetMarker.initGoogleObject(this.popupData.baseObjectId,this.popupData.icon+".gif", this.popupData.lat, this.popupData.lng, null,false, true, false)
        this.map.setCenter(new google.maps.LatLng(this.popupData.lat, this.popupData.lng))
    }

    public selectMarker(mapItem: IMapItem,loadInfo:boolean): void {
        mapItem.marker.setIcon(mapItem.icon.replace(".png", '-sel.png'))
        this.selectedMapItem = mapItem;
        this.selectedBaseObjectId = mapItem.baseObjectId;
        this.hideInfoWindows();
        if(loadInfo){
            this.openMarkerInfoWindow();
        }
    }

    private getPopupIntroText():void{
        if(this.popupIntroText == ''){
            if(this.bBoxItems.length > 0){
                this.mapDeviceMappingService.getSelectedDeviceInfo(this.popupData.baseObjectId, this.bBoxItems[0].mapItem.baseObjectId).subscribe((result:any) => {
                    this.popupIntroText = this.ts.translate('asset-mapping.description', [result.code]);
                    this.popupIntroUnlinkText = this.ts.translate('device-mapping.disconnect-current-device', [result.code]);
                    this.cd.detectChanges();
                });
            }
        }
    }

    private hideInfoWindows(){
        this.bBoxItems.map(_x => {
            _x.hideInfoWindow();
        })
    }

    private openMarkerInfoWindow():void{
        this.infoWindowLoading = true;
        this.infoWindowOpen = true;
        this.isMapBtnDisabled = true;
        this.cd.detectChanges();

        this.mapDeviceMappingService.getSelectedDeviceInfo(this.popupData.baseObjectId, this.selectedBaseObjectId).subscribe((result) => {
            this.selectedMapItemInfo = result as any;
            this.availableDriverList = [];
            this.selectedDriverToMapTo = null;

            //Build free list for lumi select
            this.selectedMapItemInfo.freeList?.map(_x => {
                this.availableDriverList.push({
                    id:_x.baseObjectId,
                    name:_x.code,
                    code:'free'
                })
            })
            //Build occupied list for lumi select
            this.selectedMapItemInfo.occupiedList?.map(_x => {
                if(_x.occupiedBy.baseObjectId != this.selectedMapItemInfo.baseObjectId){
                    this.availableDriverList.push({
                        id:_x.baseObjectId,
                        name:_x.code,
                        code:'occupied'
                    })
                }
            })

            if(this.availableDriverList.length === 1){
                this.selectedDriverToMapTo = this.availableDriverList[0];
                this.isMapBtnDisabled = false;
            }

            this.infoWindowLoading = false;
            this.cd.detectChanges();
        });
    }

    public closeMarkerInfoWindow():void{
        this.clearSelectedMarkers();
        this.infoWindowOpen = false;
        this.isMapBtnDisabled = true;
        this.selectedMapItemInfo = null;
        this.cd.detectChanges();
    }

    public closePopupClick():void{
        this.closePopup.emit();
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM, {referenceId: this.popupData.baseObjectId}));
    }

    public setSelectedDropdownOption($event:any):void{
        this.selectedDriverToMapTo = $event[0];
    }

    public unMapDevice():void{
        this.globalAlertService.addPopup(this.ts.translate("device-mapping.unlink-warning-title"), this.ts.translate("device-mapping.unlink-warning-label"),
            [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                {label:this.ts.translate("button.unlink-asset"), code:ButtonCode.OK,
                    callback:() => {
                        this.closePopup.emit();
                        this.mapDeviceMappingService.unmapDevice(this.popupData.baseObjectId).pipe(
                            take(1),
                            finalize(() => {
                                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM, {referenceId: this.popupData.baseObjectId}));
                                this.globalAlertService.addAlertSuccess(
                                    this.ts.translate('device-mapping.unmap-success-title'),
                                    this.ts.translate('device-mapping.unmap-success-subtitle'),
                                    this.ts.translate('device-mapping.unmap-success-label',[this.popupData.baseObjectId])
                                );
                            })
                        ).subscribe();
                    },
                    isPrimary:false}
            ], () => {})
    }

    public mapDevice(url:string):void{
        if(this.selectedDriverToMapTo){
            this.subscriptions.push(this.mapDeviceMappingService.mapDevice(url).subscribe((getResult) => {
                this.reopenPopup(this.popupData.baseObjectId,this.popupData.icon,this.selectedMapItem.marker.getPosition().lat(),this.selectedMapItem.marker.getPosition().lng(),true)
                this.globalAlertService.addAlertSuccess(
                    this.ts.translate('device-mapping.map-success-title'),
                    this.ts.translate('device-mapping.map-success-subtitle'),
                    this.ts.translate('device-mapping.map-success-label',[this.popupData.baseObjectId])
                );
            }));
        }
    }

    public getDisconnectCurrentDeviceText(deviceName:number):string{
        return this.ts.translate('device-mapping.disconnect-current-device', [deviceName]);
    }

    public getOccupiedDeviceById(baseObjectId:number|string):IOccupiedVsa{
        baseObjectId = Number(baseObjectId);
        if(typeof this.selectedMapItemInfo.occupiedList !== 'undefined'){
            return this.selectedMapItemInfo.occupiedList.find(_x => {return _x.baseObjectId === baseObjectId})
        } else {
            return null;
        }
    }

    public getFreeDeviceById(baseObjectId:number|string):IFreeVsa{
        baseObjectId = Number(baseObjectId);
        if(typeof this.selectedMapItemInfo.freeList !== 'undefined'){
            return this.selectedMapItemInfo.freeList.find(_x => {return _x.baseObjectId === baseObjectId})
        } else {
            return null;
        }
    }


    public switchSelectedDevice(baseObjectId:number|string):void{
        const selectedDeviceInfo:IVsaInfo = this.getOccupiedDeviceById(baseObjectId).occupiedBy;
        this.reopenPopup(selectedDeviceInfo.baseObjectId,'dev-map', selectedDeviceInfo.lat, selectedDeviceInfo.lng,true);
    }

    private reopenPopup(baseObjectId:number,icon:string,lat:number,lng:number,showUnMapButton:boolean):void{
        this.globalAlertService.addPopupDeviceMapping(
            baseObjectId,
            icon,
            lat,
            lng,
            showUnMapButton,
            (buttonCode: any, response: any) => {
                // Show success alert
                if(buttonCode == ButtonCode.UNMAP_DEVICE){
                    this.globalAlertService.addAlertSuccess(
                        this.ts.translate('asset-mapping.unmappingsuccesstitle'),
                        this.ts.translate('asset-mapping.unmappingsuccesssubtitle'),
                        this.ts.translate('asset-mapping.unmappingsuccesslabel',[baseObjectId])
                    );
                } else {
                    this.globalAlertService.addAlertSuccess(
                        this.ts.translate('asset-mapping.mappingsuccesstitle'),
                        this.ts.translate('asset-mapping.mappingsuccesssubtitle'),
                        this.ts.translate('asset-mapping.mappingsuccesslabel',[baseObjectId])
                    );
                }

                // Refresh the form and module
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MODULE_REFRESH, {invalidateCache: true}));
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM,{referenceId: baseObjectId}));
            }, () => {}
        );
        this.closePopupClick();
    }

    private getBbox(){
        const currentMapBounds = this.map.getBounds()
        if (currentMapBounds) {
            this.subscriptions.push(this.mapDeviceMappingService.getBBoxItems(currentMapBounds).subscribe((formPostResult) => {
                const formPostResultConv = formPostResult as any;
                this.clearBoundBoxItems();
                if(formPostResultConv.mapItems.filter(_x => {return _x.id == this.selectedBaseObjectId}).length == 0){
                    this.selectedBaseObjectId = null;
                    this.closeMarkerInfoWindow();
                }
                formPostResultConv.mapItems.map((_marker: IMarker) => {
                    const mapItem = new MapItem(this)
                    mapItem.initGoogleObject(_marker.id, _marker.icon+'.png', _marker.lat, _marker.lng, _marker.infoWindow, true, false, this.selectedBaseObjectId === _marker.id);
                    this.bBoxItems.push(mapItem);
                })

                this.getPopupIntroText();
            }));
        }
    }

    private clearBoundBoxItems():void{
        this.bBoxItems.map(_targetItem => {
            _targetItem.removeFromMap()
        });
        this.bBoxItems = []
    }

    public clearSelectedMarkers():void{
        this.selectedBaseObjectId = null;
        this.bBoxItems.map(_mapItem => {
            _mapItem.isSelected = false;
            _mapItem.mapItem.marker.setIcon(_mapItem.mapItem.icon.replace("-sel", ''))
        })
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
        google.maps.event.removeListener(this.eventDragend)
        google.maps.event.removeListener(this.eventZoomChanged)
    }
}
