import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'form-info',
    templateUrl: './form-info.component.html',
})

export class FormInfoComponent implements OnInit {
    @Input('config') config: any;

    public infoText:string = '';

    constructor() {}

    ngOnInit(): void {
        this.infoText = this.config?.attr?.infoText ?? '';
    }
}
