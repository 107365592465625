import {Component, Input, OnInit} from '@angular/core';
import {ISteps} from "./form-steps.interface";

@Component({
    selector: 'form-steps',
    templateUrl: './form-steps.component.html',
    styleUrls: ['./form-steps.component.scss']
})

export class FormStepsComponent implements OnInit {
    @Input('config') config: any;

    public availableSteps:ISteps[] = [];
    public currentActiveStep:number = 0;

    constructor() {}

    ngOnInit(): void {
        this.availableSteps = this.config?.attr?.menu ?? [];
        this.availableSteps.map((_x, index) => {
            if(_x.active){
                this.currentActiveStep = index;
            }
        })
    }
}
